import debounce from "lodash.debounce";

export function updateScore() {
  if (!window.initialized) {
    return;
  }

  return fetch(`/api/score?userId=${window.userId}&score=${window.score}`, {
    method: "POST"
  })
    .then(response => response.json())
    .then(result => {
      const user = result?.data?.user;
      if (user) {
        if (window.userId !== user.id) {
          window.userId = user.id;
          window.score = user.score;
          window.place = user.place || "unknown";
          localStorage.setItem("userId", user.id);
          $("#score").html(window.score);
        }
      }
    });
}

const updateScoreDebounced = debounce(updateScore, 1000);

export function setup() {
  window.targetFound = false;

  // AR 場景點擊事件
  AFRAME.registerComponent("cursor-listener", {
    canPlay: true,
    dragonFirstClicked: false,
    init: function() {
      this.el.addEventListener("loaded", () => {
        console.log("aframe loaded");

        // 為了讓三維模型先載入
        // 若不加這段，target found 才載入，會有等待的時間
        const root = $("#target")[0]?.object3D;
        if (root && root.visible === false) {
          root.visible = true;
        }

        // 隱藏 custom loading
        var customLoadingScreen = document.getElementById(
          "customLoadingScreen"
        );
        customLoadingScreen.style.display = "none";
      });
      this.el.addEventListener("arReady", () => {
        console.log("arReady");

        // 為了讓三維模型先載入
        // 若不加這段，target found 才載入，會有等待的時間
        // 隱藏是為了 target found, 不隱藏的話，target found 會沒有效果
        const root = $("#target")[0]?.object3D;
        if (root && root.visible === true) {
          root.visible = false;
        }

        // 隱藏金幣
        for (let i = 1; i <= 10; i++) {
          const coinEl = document.getElementById("gold" + i);
          coinEl.setAttribute("visible", "false");
        }

        // 調整攝像頭 video, 加上 styles.css
        // 這是為了解決 ios 低電量模式下，攝像頭會有 play button 出現的問題
        // https://stackoverflow.com/questions/73695619/hide-ios-safari-native-low-power-play-button
        $("#scene-wrapper > video")[0].autoplay = false;
        $("#scene-wrapper > video")[0].pause();
        $("#scene-wrapper > video")[0].play();
        $("#scene-wrapper > video").show();
      });

      // 處理攝像頭權限問題
      this.el.addEventListener("arError", event => {
        if (event && event.detail && event.detail.error === "VIDEO_FAIL") {
          $("#start-modal").show();
          $("#warning-text").html(
            `請關閉並重開瀏覽器，並允許此網頁使用攝像頭權限。`
          );
          $("#warning-camera").hide();
          $("#warning").show();
          // 隱藏 mind-ar loading 畫面
          $(".mindar-ui-overlay").hide();
        }
      });

      for (let i = 1; i <= 10; i++) {
        const coinEl = document.getElementById("gold" + i);
        let index = i;
        coinEl.addEventListener("click", () => {
          if (!window.firstClicked) {
            window.firstClicked = true;
            $("#score-wrapper").fadeIn();
          }
          // play sound
          window.clickSound.play();

          window.score += 1;
          $("#score").html(window.score);
          coinEl.setAttribute("visible", "false");
          $("#gold" + index + "-box").removeClass("clickable");
          updateScoreDebounced();
        });
      }

      const boxEl = document.getElementById("dragon-box");
      boxEl.addEventListener("click", () => {
        if (this.dragonFirstClicked === false) {
          this.dragonFirstClicked = true;
          $("#click-tips").fadeOut();
        }
        if (this.canPlay === false) {
          return;
        }
        this.canPlay = false;

        $("#dragon-box").removeClass("clickable");

        // play sound
        window.coins.play();

        // dragon animation
        const dragonEl = document.getElementById("dragon");
        dragonEl.setAttribute("animation__scaleup", {
          property: "scale",
          to: `0.035 0.035 0.035`,
          dur: 250,
          easing: "easeInQuad"
        });
        dragonEl.setAttribute("animation__scaledown", {
          property: "scale",
          from: "0.035 0.035 0.035",
          to: `0.04 0.04 0.04`,
          delay: 250,
          dur: 250,
          easing: "easeOutQuad"
        });
        dragonEl.setAttribute("animation__scaleorigin", {
          property: "scale-origin",
          to: "center"
        });
        setTimeout(() => {
          dragonEl.removeAttribute("animation__scaleup");
          dragonEl.removeAttribute("animation__scaledown");
        }, 3000);

        const position = { x: 0, y: -0.7, z: 0 };
        for (let i = 1; i <= 10; i++) {
          const coinEl = document.getElementById("gold" + i);
          $("#gold" + i + "-box").addClass("clickable");

          coinEl.setAttribute("visible", "true");
          coinEl.setAttribute(
            "position",
            `${position.x} ${position.y} ${position.z}`
          );

          // Randomize the direction and strength of the "pop" effect
          const x = (Math.random() - 0.5) * 2.2;
          const y = Math.random() * 1 + 1;
          const z = (Math.random() - 0.5) * 1;

          // Randomize rotation for a more natural effect
          const rx = Math.random() * 360;
          const ry = Math.random() * 360;
          const rz = Math.random() * 360;
          coinEl.setAttribute("rotation", `${rx} ${ry} ${rz}`);

          const scale = Math.random() * 0.1 + 0.15;
          coinEl.setAttribute("scale", `${scale} ${scale} ${scale}`);

          // Create the animation for the coin to move up and then down
          coinEl.setAttribute("animation__up", {
            property: "position",
            to: `${position.x + x} ${position.y + y + 0.2} ${position.z +
              z +
              1}`,
            dur: 1500,
            easing: "easeOutQuad"
          });

          coinEl.setAttribute("animation__down", {
            property: "position",
            from: `${position.x + x} ${position.y + y + 0.2} ${position.z +
              z +
              1}`,
            to: `${position.x + x} ${position.y} ${position.z + z + 1}`,
            delay: 1500,
            dur: 1500,
            easing: "easeInQuad"
          });

          setTimeout(() => {
            coinEl.setAttribute("visible", "false");
            $("#dragon-box").addClass("clickable");
            for (let i = 1; i <= 10; i++) {
              $("#gold" + i + "-box").removeClass("clickable");
            }
            this.canPlay = true;
          }, 3000);
        }
      });

      // targetFound and lost 事件, 處理背景音樂
      const target = document.getElementById("target");
      target.addEventListener("targetFound", () => {
        window.audio.play();
        window.targetFound = true;
        console.log("targetFound!!!");
        if (!window.isModalOpen && window.firstClicked) {
          $("#score-wrapper").fadeIn();
        }
        $("#rank-btn").fadeIn();
        $("#place-btn").fadeIn();
        $("#photo-btn").fadeIn();
        if (this.dragonFirstClicked === false) {
          $("#click-tips").fadeIn();
        }
      });
      // detect target lost
      target.addEventListener("targetLost", () => {
        window.audio.currentTime = 0;
        window.audio.pause();
        window.targetFound = false;
        console.log("targetLost!!!");
        $("#score-wrapper").fadeOut();
        $("#rank-btn").fadeOut();
        $("#place-btn").fadeOut();
        $("#photo-btn").fadeOut();
        if (this.dragonFirstClicked === false) {
          $("#click-tips").hide();
        }
      });
    }
  });
}
