import { Howl, Howler } from "howler";

export function setup() {
  window.audio = new Audio("bg.mp3");
  window.audio.loop = true;
  window.coins = new Howl({
    src: ["coins.mp3"]
  });
  window.clickSound = new Howl({
    src: ["click.mp3"]
  });
}
