import { setup as setupIntro } from "./lib/intro";
import { setup as setupAframe } from "./lib/aframe";
import { setup as setupUtils } from "./lib/utils";
import { setup as setupSound } from "./lib/sound";
import { setup as setupUI } from "./lib/ui";

function start() {
  // 開始頁面效果實現
  // 處理開始按鈕的事件
  setupIntro();

  // AR 場景點擊事件
  // targetFound and lost 事件, 處理背景音樂
  setupAframe();

  // disable mobile browser 的 pinch zoom 和 double tap zoom
  // warning 檢查
  setupUtils();

  // 背景音樂
  setupSound();

  // ui
  setupUI();
}

start();
