// 添加輝哥的瀏覽器提示
// https://github.com/withcloud/mindar-congratulations-picture-demo/blob/browser/src/index.html
const browser = {
  // 版本信息
  versions: (function() {
    var u = navigator.userAgent;
    return {
      //移动终端浏览器版本信息
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1, //android终端或uc浏览器
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") == -1 //是否web应该程序，没有头部与底部
    };
  })(),
  language: (navigator.browserLanguage || navigator.language).toLowerCase(),
  // 打開對象
  openObject: (function() {
    var ua = navigator.userAgent;
    var u = navigator.userAgent.toLowerCase(); //获取判断用的对象
    return {
      weixin: u.match(/MicroMessenger/i) == "micromessenger",
      qq: u.match(/QQ/i) == "qq",
      weibo: u.match(/WeiBo/i) == "weibo",
      alipay: /alipay/gi.test(u),
      dingtalk: u.indexOf("dingtalk") !== -1,
      chrome: u.indexOf("Chrome") > -1,
      isChrome: /(?:Chrome|CriOS)/.test(ua),
      isSafari: /Safari/.test(ua), //蘋果內置瀏覽器  百度
      baidui: u.indexOf("baidu") > 0,
      ios: /iphone|ipod|ipad/.test(u)
    };
  })()
};

function checkWebGLSupport() {
  var canvas = document.createElement("canvas");
  var webgl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

  if (webgl && webgl instanceof WebGLRenderingContext) {
    // 瀏覽器支持 WebGL，Three.js 應該也能運行
    console.log("WebGL is supported, Three.js should also be supported.");
    return true;
  } else {
    // 瀏覽器不支持 WebGL，Three.js 將無法運行
    console.log("WebGL is not supported, Three.js will not be able to run.");
    return false;
  }
}

export function setup() {
  // 不斷顯示三維模型
  // setInterval(() => {
  //   const root = $("#target")[0]?.object3D;
  //   if (root && root.visible === false) {
  //     root.visible = true;
  //   }
  // }, 1000);

  // disable mobile browser 的 pinch zoom 和 double tap zoom
  // https://stackoverflow.com/a/66798697
  let drags = new Set(); //set of all active drags
  document.addEventListener("touchmove", function(event) {
    if (!event.isTrusted) return; //don't react to fake touches
    Array.from(event.changedTouches).forEach(function(touch) {
      drags.add(touch.identifier); //mark this touch as a drag
    });
  });
  document.addEventListener("touchend", function(event) {
    if (!event.isTrusted) return;
    let isDrag = false;
    Array.from(event.changedTouches).forEach(function(touch) {
      if (drags.has(touch.identifier)) {
        isDrag = true;
      }
      drags.delete(touch.identifier); //touch ended, so delete it
    });
    if (!isDrag && document.activeElement == document.body) {
      //note that double-tap only happens when the body is active
      event.preventDefault(); //don't zoom
      event.stopPropagation(); //don't relay event
      event.target.focus(); //in case it's an input element
      event.target.click(); //in case it has a click handler
      event.target.dispatchEvent(new TouchEvent("touchend", event));
      //dispatch a copy of this event (for other touch handlers)
    }
  });

  // 添加屏幕轉向監控
  const screenDirection = window.innerHeight > window.innerWidth;
  setInterval(() => {
    if (screenDirection !== window.innerHeight > window.innerWidth) {
      window.location.reload();
    }
  }, 1000);

  window.hasWarning = false;

  // checkout browser
  // 微信檢查
  // 移動端
  if (!window.hasWarning) {
    if (
      /Android|Adr|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      //非ios處理  chrome  safari weixin
      if (browser.versions.mobile) {
        if (browser.openObject.weixin) {
          window.hasWarning = true;
          $("#warning-text").html(
            `點擊右上角 “三個點 … ” 按鈕，
          <br />
          並選擇 “在預設瀏覽器中打開”。`
          );
        } else if (
          browser.openObject.dingtalk ||
          browser.openObject.qq ||
          browser.openObject.baidui
        ) {
          // do nothing
        } else {
          // do nothing
        }
      }
    } else {
      // pc端
      // do nothing
    }
  }

  // check webgl
  if (!window.hasWarning) {
    if (checkWebGLSupport() === false) {
      window.hasWarning = true;
      $("#warning-text").html(
        `抱歉，您的瀏覽器不支持 WebGL。<br />請嘗試使用其他瀏覽器檢視此網頁。<br />(例如 Google Chrome 瀏覽器)`
      );
    }
  }

  if (window.hasWarning) {
    $("#intro").hide();
    $("#warning-camera").hide();
    $("#warning").show();
  } else {
    $("#intro").fadeIn();
  }

  // 為了解決手機瀏覽器上一頁下一頁的 bf cache 問題
  window.addEventListener("pageshow", function(event) {
    if (event.persisted) {
      window.location.reload();
    }
  });
}
