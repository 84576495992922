export function setup() {
  // 開始頁面效果實現
  try {
    window.effect = VANTA.WAVES({
      el: "#bg",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      color: 0x932626
    });
  } catch (error) {
    // do nothing
  }

  // 處理開始按鈕的事件
  $("#start-btn").click(function() {
    // 1. 隱藏開始頁面
    $("#start-modal").hide();
    $("#intro").hide();
    // 2. 銷毀背景效果
    window.effect.destroy();
    // 3. 背景音樂處理，必須在按按鈕時才能播放
    window.audio.play();
    if (!window.targetFound) {
      window.audio.pause();
    }

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      $("#start-modal").show();
      $("#warning-text").html(
        `請關閉並重開瀏覽器，並允許此網頁使用攝像頭權限。`
      );
      $("#warning-camera").hide();
      $("#warning").show();
      return;
    }

    const timer = setTimeout(() => {
      $("#start-modal").show();
      $("#warning-text").html("");
      $("#warning-camera").show();
      $("#warning").show();
      $("#scene-wrapper").hide();
      $(".mindar-ui-overlay").hide();
    }, 6000);

    const video = document.createElement("video");
    navigator.mediaDevices
      .getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
          width: { ideal: 640 },
          height: { ideal: 480 }
        }
      })
      .then(stream => {
        video.addEventListener(
          "loadedmetadata",
          () => {
            clearTimeout(timer);

            $("#start-modal").hide();
            $("#warning").hide();
            $("#warning-camera").hide();
            $(".mindar-ui-overlay").show();

            console.log("video ready...", video);
            console.log("width", video.videoWidth);
            console.log("height", video.videoHeight);

            // 移除 video
            // 測試完畢後，立即關閉流
            stream.getTracks().forEach(track => track.stop());
            // 移除 video 元素
            video.remove();

            // 顯示 ar 頁面
            $("#scene-wrapper").show();
            // 把 ar 頁面加回去
            $("#scene-wrapper").append(window.$scene);

            // 更新进度函数

            // 绑定进度事件到所有的 asset-item 上
            var assets = document.querySelectorAll("a-asset-item");
            const totalArray = [];
            const loadedArray = [];
            for (var i = 0; i < assets.length; i++) {
              let index = i;
              assets[index].addEventListener("progress", evt => {
                totalArray[index] = evt.detail.totalBytes;
                loadedArray[index] = evt.detail.loadedBytes;
                // console.log(evt.detail, totalArray, loadedArray);
                const total = 1772336;
                const loaded = loadedArray.reduce((a, b) => a + b, 0);
                // 计算加载的百分比
                var percentage = (loaded / total) * 100;
                // 更新你的自定义加载屏幕上的进度显示
                $("#progress").text(parseInt(percentage) + "%");
                $("#progress").css("width", parseInt(percentage) + "%");
              });
            }
          },
          { once: true }
        );
        video.srcObject = stream;
      })
      .catch(err => {
        console.log("getUserMedia error", err);
        $("#start-modal").show();
        $("#warning-text").html(
          `請關閉並重開瀏覽器，並允許此網頁使用攝像頭權限。`
        );
        $("#warning-camera").hide();
        $("#warning").show();
      });
  });

  // 參數初始化
  window.score = 0;
  window.place = "unknown";
  window.initialized = false;

  // 處理用戶
  window.userId = localStorage.getItem("userId") || "";
  // 先暫時存一個
  if (!window.userId) {
    localStorage.setItem("userId", "");
  }
  // 無論有沒有用戶都要先 fetch 一次
  fetch("/api/user?id=" + window.userId, {
    method: "POST"
  })
    .then(response => response.json())
    .then(result => {
      const user = result?.data?.user;
      if (user) {
        window.userId = user.id;
        window.score = user.score;

        window.place = user.place || "unknown";

        localStorage.setItem("userId", user.id);
        // $("#score").html(window.score);

        window.initialized = true;
      }
    });
}
