import { createAvatar } from "@dicebear/core";
import { adventurerNeutral } from "@dicebear/collection";
import { v4 as uuidv4 } from "uuid";
import { updateScore } from "./aframe";

function isPointInsidePolygon(point, polygon) {
  var x = point[0],
    y = point[1];
  var inside = false;
  for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    var xi = polygon[i][0],
      yi = polygon[i][1];
    var xj = polygon[j][0],
      yj = polygon[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

var polygons = [
  [
    [113.52657, 22.21813],
    [113.59708, 22.21813],
    [113.59708, 22.20235],
    [113.52657, 22.20235]
  ],
  [
    [113.52657, 22.20235],
    [113.59708, 22.20235],
    [113.59708, 22.18844],
    [113.52657, 22.18844]
  ],
  [
    [113.52657, 22.18844],
    [113.59708, 22.18844],
    [113.59708, 22.17219],
    [113.52657, 22.17219]
  ],
  [
    [113.52657, 22.17219],
    [113.59708, 22.17219],
    [113.59708, 22.15202],
    [113.52657, 22.15202]
  ],
  [
    [113.52657, 22.15202],
    [113.59708, 22.15202],
    [113.59708, 22.13151],
    [113.52657, 22.13151]
  ],
  [
    [113.52657, 22.13151],
    [113.59708, 22.13151],
    [113.59708, 22.10932],
    [113.52657, 22.10932]
  ]
];

const placesArray = [
  "澳門北區",
  "澳門中區",
  "澳門南區",
  "氹仔",
  "路氹",
  "路環",
  "unknown"
];

function getUserWindow(users, userId) {
  // 找到當前用戶在數組中的索引
  let currentIndex = users.findIndex(user => user.id === userId);

  // 如果用戶不在數組中，則返回 1-5 筆記錄
  if (currentIndex === -1) {
    currentIndex = 0;
  }

  // 根據當前用戶的位置決定 slice 的起始和結束位置
  let start = Math.max(0, currentIndex - 2);
  let end = Math.min(users.length, currentIndex + 3);

  // 如果當前用戶是前兩名，則返回前五筆記錄
  if (currentIndex === 0 || currentIndex === 1) {
    start = 0;
    end = 5;
  }
  // 如果當前用戶是最後兩名，則返回最後五筆記錄
  else if (
    currentIndex === users.length - 1 ||
    currentIndex === users.length - 2
  ) {
    start = Math.max(0, users.length - 5);
    end = users.length;
  }

  for (let i = start; i < end; i++) {
    if (users[i]) {
      users[i].rank = i + 1;
    }
  }

  // 返回用戶數組的一個子集
  return users.slice(start, end);
}

export function setup() {
  window.isModalOpen = false;
  $("#rank-close-btn").click(function() {
    $("#rank-modal").hide();
    if (window.targetFound && window.firstClicked) {
      $("#score-wrapper").show();
    }
    window.isModalOpen = false;
  });

  $("#rank-btn").click(async function() {
    window.isModalOpen = true;
    $("#rank-loader").show();
    $("#rank-list").empty();
    $("#rank-list").show();
    $("#rank-modal").show();
    if (window.targetFound) {
      $("#score-wrapper").hide();
    }

    await updateScore();

    // loading
    fetch("/api/users", {
      method: "POST"
    })
      .then(response => response.json())
      .then(result => {
        const users = result?.data?.users;
        console.log(users);
        $("#rank-loader").hide();
        $("#rank-list").show();
        getUserWindow(users, window.userId).forEach((user, index) => {
          const avatar = createAvatar(adventurerNeutral, {
            seed: user.name
          });
          $("#rank-list").append(`
            <li class="flex justify-between gap-x-6 py-5 px-6 ${
              window.userId === user.id ? "bg-green-200" : ""
            }">
              <div class="flex min-w-0 gap-x-4">
                <img
                  class="h-12 w-12 flex-none rounded-full bg-gray-50"
                  src="${avatar.toDataUriSync()}"
                  alt=""
                />
                </div>
                <div class="min-w-0 flex items-center">
                  <p
                    class="text-3xl font-semibold leading-6 text-gray-900"
                  >
                    #${user.rank}
                  </p>
                </div>
                <div class="min-w-0 flex-auto flex items-center">
                  ${
                    window.userId === user.id
                      ? `
                        <p
                          class="rounded-md whitespace-nowrap mt-0.5 px-3 py-1 text-base font-medium ring-1 ring-inset text-green-600 bg-green-50 ring-green-500/10"
                        >
                          您!
                        </p>
                      `
                      : ""
                  }
                </div>
              </div>
              <div class="shrink-0 flex items-center">
                <p id="${
                  user.id
                }-score" class="count italic text-xl leading-6 text-gray-900" data-value="${
            user.score
          }">
                  ${user.score}
                </p>
              </div>
            </li>
          `);
        });
        $(".count").each(function() {
          $(this)
            .prop("Counter", 0)
            .animate(
              {
                Counter: $(this).data("value")
              },
              {
                duration: 500,
                easing: "swing",
                step: function(now) {
                  $(this).text(this.Counter.toFixed(0));
                }
              }
            );
        });
      });
  });

  $("#place-close-btn").click(function() {
    $("#place-modal").hide();
    if (window.targetFound && window.firstClicked) {
      $("#score-wrapper").show();
    }
    window.isModalOpen = false;
  });

  $("#place-btn").click(function() {
    window.isModalOpen = true;
    $("#place-loader").show();
    $("#place-list").hide();
    $("#place1").text("");
    $("#place2").text("");
    $("#place3").text("");
    $("#place4").text("");
    $("#place5").text("");
    $("#place6").text("");
    $("#place7").text("");
    $("#score1").text("");
    $("#score2").text("");
    $("#score3").text("");
    $("#score4").text("");
    $("#score5").text("");
    $("#score6").text("");
    $("#score7").text("");
    $("#place-modal").show();
    if (window.targetFound) {
      $("#score-wrapper").hide();
    }

    // 需要獲取用戶位置
    if (window.place === "unknown") {
      if ("geolocation" in navigator) {
        const timer = setTimeout(() => {
          loadPlaceData();
        }, 5000);

        // geolocation 可用
        navigator.geolocation.getCurrentPosition(
          function(position) {
            // 用戶授予權限
            var latitude = position.coords.latitude;
            var longitude = position.coords.longitude;
            console.log("緯度: " + latitude + ", 經度: " + longitude);
            var geoCoordinate = [longitude, latitude];
            let place = "unknown";
            for (var i = 0; i < polygons.length; i++) {
              if (isPointInsidePolygon(geoCoordinate, polygons[i])) {
                place = placesArray[i];
                break;
              }
            }
            console.log(place);
            if (window.initialized) {
              fetch(`/api/place?userId=${window.userId}&place=${place}`, {
                method: "POST"
              }).then(() => {
                clearTimeout(timer);
                window.place = place;
                loadPlaceData();
              });
            } else {
              clearTimeout(timer);
              loadPlaceData();
            }
          },
          function(error) {
            clearTimeout(timer);
            // 用戶拒絕權限或者其他錯誤
            console.log("無法獲取位置: " + error.message);
            loadPlaceData();
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
          }
        );
      } else {
        // geolocation 不可用
        console.log("您的瀏覽器不支持地理位置功能。");
        loadPlaceData();
      }
    } else {
      loadPlaceData();
    }
  });

  $("#photo-close-btn").click(function() {
    $("#photo-modal").hide();
    if (window.targetFound && window.firstClicked) {
      $("#score-wrapper").show();
    }
    window.isModalOpen = false;
  });

  window.photoBtnClicked = false;

  $("#photo-btn").click(function() {
    if (window.photoBtnClicked) {
      return;
    }
    window.photoBtnClicked = true;

    setTimeout(() => {
      window.photoBtnClicked = false;

      window.isModalOpen = true;
      $("#photo-modal").show();
      $("#photo-loader").show();
      $("#photo-wrapper").hide();
      if (window.targetFound) {
        $("#score-wrapper").hide();
      }

      try {
        var video = document.querySelector("video");
        var videoStyle = window.getComputedStyle(video);

        // 创建新的 canvas 元素
        var newCanvas = document.createElement("canvas");
        // document.body.appendChild(newCanvas);

        // 将视频的 CSS 宽度和高度设置为新 canvas 的宽度和高度
        const scaledX = parseFloat(videoStyle.left) * -1;
        const scaledY = parseFloat(videoStyle.top) * -1;
        const scaleX = video.videoWidth / parseFloat(videoStyle.width);
        const scaleY = video.videoHeight / parseFloat(videoStyle.height);
        const oriX = scaledX * scaleX;
        const oriY = scaledY * scaleY;
        const oriWidth = video.videoWidth - oriX * 2;
        const oriHeight = video.videoHeight - oriY * 2;
        const scaledWidth = parseFloat(videoStyle.width) - scaledX * 2;
        const scaledHeight = parseFloat(videoStyle.height) - scaledY * 2;

        const height = 2048;
        const width = parseInt((height * scaledWidth) / scaledHeight);

        newCanvas.width = width;
        newCanvas.height = height;
        var newContext = newCanvas.getContext("2d");

        // 确保视频已经加载了足够的数据
        if (video.readyState >= 2) {
          // 从视频的可见部分截取图像，并绘制到新的 canvas 上
          newContext.drawImage(
            video,
            oriX,
            oriY,
            oriWidth,
            oriHeight,
            0,
            0,
            width,
            height
          );
          document.querySelector("a-scene").setAttribute("screenshot", {
            width: width,
            height: height
          });
          const canvas = document
            .querySelector("a-scene")
            .components.screenshot.getCanvas("perspective");
          newContext.drawImage(canvas, 0, 0);
          newCanvas.toBlob(
            file => {
              save(file);
            },
            "image/jpeg",
            0.9
          );
        } else {
          console.error("Video is not ready.");
        }
      } catch (error) {
        console.log(error);
      }
    }, 300);
  });
}

async function save(file) {
  // save to s3
  const res = await fetch(
    `/api/upload-url?t=${Date.now()}&key=apps/redpacket/photos/${uuidv4() +
      ".png"}`,
    {
      method: "POST"
    }
  );
  const result = await res.json();
  const { url, fields } = result.data.post;
  console.log(result);

  const formData = new FormData();

  Object.entries({ ...fields, file }).forEach(([key, value]) => {
    formData.append(key, value);
  });

  const upload = await fetch(url, {
    method: "POST",
    body: formData
  });

  if (upload.ok) {
    $("#photo-loader").hide();
    $("#photo-wrapper").show();
    const photoUrl = url + "/" + fields.key;
    $("#photo-wrapper > img").attr("src", photoUrl);
  } else {
    console.error("Upload failed.");
  }
}

function loadPlaceData() {
  $("#your-place").text(window.place === "unknown" ? "其他地區" : window.place);

  // loading
  fetch("/api/places", {
    method: "POST"
  })
    .then(response => response.json())
    .then(result => {
      const places = result?.data?.places;
      console.log(places);
      $("#place-loader").hide();
      $("#place-list").show();
      const arr = [...placesArray];
      places.forEach(obj => {
        const place = obj.place;
        const index = arr.indexOf(place);
        if (index > -1) {
          // 如果存在，使用 splice 移除它
          arr.splice(index, 1);
        }
      });
      arr.forEach(item => {
        places.push({ _sum: { score: 0 }, place: item });
      });
      console.log(places);
      $("#place1").text(
        places[0].place === "unknown" ? "其他地區" : places[0].place
      );
      $("#place2").text(
        places[1].place === "unknown" ? "其他地區" : places[1].place
      );
      $("#place3").text(
        places[2].place === "unknown" ? "其他地區" : places[2].place
      );
      $("#place4").text(
        places[3].place === "unknown" ? "其他地區" : places[3].place
      );
      $("#place5").text(
        places[4].place === "unknown" ? "其他地區" : places[4].place
      );
      $("#place6").text(
        places[5].place === "unknown" ? "其他地區" : places[5].place
      );
      $("#place7").text(
        places[6].place === "unknown" ? "其他地區" : places[6].place
      );
      $("#score1").text(places[0]._sum.score);
      $("#score2").text(places[1]._sum.score);
      $("#score3").text(places[2]._sum.score);
      $("#score4").text(places[3]._sum.score);
      $("#score5").text(places[4]._sum.score);
      $("#score6").text(places[5]._sum.score);
      $("#score7").text(places[6]._sum.score);
      $("#score1").data("value", places[0]._sum.score);
      $("#score2").data("value", places[1]._sum.score);
      $("#score3").data("value", places[2]._sum.score);
      $("#score4").data("value", places[3]._sum.score);
      $("#score5").data("value", places[4]._sum.score);
      $("#score6").data("value", places[5]._sum.score);
      $("#score7").data("value", places[6]._sum.score);
      if (places[0].place === window.place) {
        $("#place-item1").addClass("bg-green-200");
      } else {
        $("#place-item1").removeClass("bg-green-200");
      }
      if (places[1].place === window.place) {
        $("#place-item2").addClass("bg-green-200");
      } else {
        $("#place-item2").removeClass("bg-green-200");
      }
      if (places[2].place === window.place) {
        $("#place-item3").addClass("bg-green-200");
      } else {
        $("#place-item3").removeClass("bg-green-200");
      }
      if (places[3].place === window.place) {
        $("#place-item4").addClass("bg-green-200");
      } else {
        $("#place-item4").removeClass("bg-green-200");
      }
      if (places[4].place === window.place) {
        $("#place-item5").addClass("bg-green-200");
      } else {
        $("#place-item5").removeClass("bg-green-200");
      }
      if (places[5].place === window.place) {
        $("#place-item6").addClass("bg-green-200");
      } else {
        $("#place-item6").removeClass("bg-green-200");
      }
      if (places[6].place === window.place) {
        $("#place-item7").addClass("bg-green-200");
      } else {
        $("#place-item7").removeClass("bg-green-200");
      }
      // https://stackoverflow.com/questions/23006516/jquery-animated-number-counter-from-zero-to-value
      $(".count").each(function() {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).data("value")
            },
            {
              duration: 500,
              easing: "swing",
              step: function(now) {
                $(this).text(this.Counter.toFixed(0));
              }
            }
          );
      });
    });
}
